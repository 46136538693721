import React, { useState, useEffect, useCallback, useRef } from 'react';
import './carrossel.css';

import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";
import testeSlider2 from '../../../assets/img/testeslider2.png';
import testeSlider3 from '../../../assets/img/testeslider3.png'; 
import testeSlider4 from '../../../assets/img/testeslider4.png';
import testeSlider5 from '../../../assets/img/testeslider5.png';


const Carrossel = () => {
  const images = [
    testeSlider2, // Usando a imagem importada
    testeSlider3,
    testeSlider4,
    testeSlider5
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const delay = 10000; // Tempo para cada imagem (10 segundos)
  const timerRef = useRef(null); // Referência ao temporizador ativo

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const prevImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images.length]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(nextImage, delay);
  }, [nextImage, delay]);

  // Bloqueia transições rápidas e reinicia o timer
  const handleNextImage = () => {
    if (isTransitioning) return; // Impede a mudança de imagem enquanto está em transição
    setIsTransitioning(true);
    resetTimer(); // Reseta o temporizador ao clicar
    nextImage();
  };

  const handlePrevImage = () => {
    if (isTransitioning) return; // Impede a mudança de imagem enquanto está em transição
    setIsTransitioning(true);
    resetTimer(); // Reseta o temporizador ao clicar
    prevImage();
  };

  useEffect(() => {
    resetTimer();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [currentIndex, resetTimer]);

  // Reseta o estado de transição após a animação
  useEffect(() => {
    if (isTransitioning) {
      const transitionTimeout = setTimeout(() => {
        setIsTransitioning(false); // Permite a transição novamente após a animação
      }, 1000); // Aguardar 1 segundo para a transição
      return () => clearTimeout(transitionTimeout);
    }
  }, [isTransitioning]);

  return (
    <div className="carrossel">
      {images.map((image, index) => {
        let className = '';
        if (index === currentIndex) {
          className = 'active';
        } else if (
          index === (currentIndex - 1 + images.length) % images.length
        ) {
          className = 'previous';
        } else {
          className = 'next';
        }

        return <img key={index} src={image} alt={`Imagem ${index + 1}`} className={className} />;
      })}

      <div className="controls">
        <button className="prev" onClick={handlePrevImage} disabled={isTransitioning}>
          <SlArrowLeft />
        </button>
        <button className="next" onClick={handleNextImage} disabled={isTransitioning}>
          <SlArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Carrossel;
