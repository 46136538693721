import React from "react";
import "../Empresa/empresa.css";

function Empresa() {
    return (
        <div className="empresa">
            <h1>Empresa</h1>
            <br />
            <h2>Sobre nós</h2>
            <br />
            <p>Somos uma empresa localizada em São Paulo especializada em oferecer soluções completas para clínicas odontológicas, atendendo às necessidades de dentistas com serviços de alta qualidade e produtos de confiança. Nossa missão é proporcionar o melhor suporte técnico e infraestrutura para profissionais da odontologia, para que eles possam focar no que fazem de melhor: cuidar de seus pacientes.</p>
            <br />
            <h2>Serviços prestados</h2>
            <br />
            <p>Oferecemos uma linha abrangente de serviços, incluindo:</p> 
            
            <p><strong>Manutenção e Conserto de Equipamentos Odontológicos:</strong> Realizamos serviços específicos em diferentes tipos de equipamentos, incluindo:</p>
            
                <ul>
                    <ul>
                        <li>Manutenção de Cadeira</li>
                        <li>Manutenção de compressores</li>
                        <li>Manutenção de Bomba Vácuo</li>
                        <li>Manutenção de Autoclave</li>
                        <li>Manutenção de Raio X</li>
                        <li>Manutenção de Motor de Implante</li>
                        <li>Manutenção de Aparelho de Prof.</li>
                        <li>Manutenção de Ultrassom</li>
                        <li>Manutenção de Motor de Bancada</li>
                        <li>Manutenção de Placas</li>
                        <li>Manutenção de Periféricos</li>
                    </ul>
                </ul>
           
            <br />
            

            <p><strong>Compra e Venda de Equipamentos e Materiais Odontológicos:</strong> Oferecemos uma ampla seleção de produtos de alta qualidade, como:</p>

                <ul>
                    <ul>
                        <li>Equipamentos de imagem</li>
                        <li>Instrumentais odontológicos</li>
                    </ul>
                </ul>    
           
            <br />
                
            <p><strong>Suporte Técnico Especializado:</strong> Prestamos suporte para resolver tanto problemas simples quanto desafios técnicos mais exigentes. Nosso atendimento inclui:</p>
                    
                <ul> 
                    <ul>
                        <li>Manutenções de rotina</li>
                        <li>Resolução de problemas complexos</li>
                        <li>Consultoria técnica personalizada</li>
                    </ul>
                </ul> 
            
            <br />

            <h2>Horário de Serviço</h2>
            <br />
            <p>Nossos horários de serviço são:</p>
            <ul>
                <li>
                    <strong>Segunda a Sexta-feira:</strong> das 9h às 16h. 
                </li>
            </ul>
            <br />

            <h2>Local de Atendimento</h2>
            <br />
            <p>Nós prestamos nossos serviços em todas as regiões de <strong>São Paulo</strong>.</p>
            <br />
            <p>Nosso compromisso é oferecer um atendimento completo e confiável, facilitando o dia a dia dos dentistas e ajudando-os a manter seus consultórios em perfeito funcionamento.</p>
        </div>
    );
}

export default Empresa;
