import React from "react";
import { Link } from 'react-router-dom';
import '../Header/header.css';


function Header (){
    return(
    <div className="header">
        
        <h1>Flashdent</h1>
        
        <ul className="listHeader">
            <li className="itemHeader">
                <Link to="/">Home</Link>
            </li >
            <li className="itemHeader">
                <Link to="/empresa">Empresa</Link>  
            </li>
            <li className="itemHeader">
                <Link to="/contato">Contato</Link>  
            </li>
        </ul>
    </div>    
    );
}

export default Header; 