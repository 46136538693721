import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './emailjs.css'; 

const Email = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '', 
    service: '', // Agora, o valor será o tipo de serviço selecionado
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); 

    const serviceID = 'service_h9uaq36'; // Substitua pelo seu Service ID do painel do EmailJS
    const templateID = 'template_qolm1io'; // Substitua pelo seu Template ID do painel
    const userID = 'P4nqIWxH0BNChdLM3'; // Substitua pelo seu User ID (opcional)
    
    const templateParams = {
      from_name: formData.name, // Nome preenchido no formulário
      from_email: formData.email, // Novo campo: e-mail do remetente
      to_name: 'Destinatário', // Substitua pelo nome do destinatário (ou deixe genérico)
      message: formData.message, // Mensagem do formulário
      service: formData.service, // Serviço solicitado
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then(
        (response) => {
          setSuccessMessage('Solicitação enviada com sucesso!');
          setFormData({ name: '', email: '', service: '', message: '' });
        },
        (error) => {
          setSuccessMessage('Ocorreu um erro. Tente novamente mais tarde.');
        }
      )
      .finally(() => setIsSubmitting(false)); // Finaliza o estado de envio
  };

  return (
    <div className="service-request-form-container">
      <h2>Solicite um Serviço</h2>
      <form onSubmit={handleSubmit} className="service-request-form">
        <label htmlFor="name">Nome:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">E-mail:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="service">Serviço Solicitado:</label>
        <select
          id="service"
          name="service"
          value={formData.service}
          onChange={handleChange}
          required
        >
          <option value="">Selecione o serviço</option>
          <option value="manutencao1">Manutenção de Cadeira</option>
          <option value="manutencao2">Manutenção de Compressores</option>
          <option value="manutencao3">Manutenção de Bomba Vácuo</option>
          <option value="manutencao4">Manutenção de Autoclave</option>
          <option value="manutencao5">Manutenção de Raio X</option>
          <option value="manutencao6">Manutenção de Motor de Implante</option>
          <option value="manutencao7">Manutenção de Aparelho de Prof.</option>
          <option value="manutencao8">Manutenção de Ultrassom</option>
          <option value="manutencao9">Manutenção de Motor de Bancada</option>
          <option value="manutencao10">Manutenção de Placas</option>
          <option value="manutencao10">Manutenção de Periféricos</option>
          <option value="compraevenda1">Compra de Equipamentos</option>
          <option value="compraevenda2">Venda de Equipamentos</option>
        </select>

        <label htmlFor="message">Mensagem:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="5"
          required
        ></textarea>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default Email;
