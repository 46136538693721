import React from "react";
import { Link } from "react-router-dom";
import "../Footer/footer.css";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Mapa do site */}
                <div className="footer-section">
                    <h4>MAPA DO SITE</h4>
                    <ul className="listFooter">
                        <li className="itemFooter">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="itemFooter">
                            <Link to="/empresa">Empresa</Link>
                        </li>
                        <li className="itemFooter">
                            <Link to="/contato">Contato</Link>
                        </li>
                        <li className="itemFooter">
                            <Link to="/termos">Termos de Uso</Link>
                        </li>
                        <li className="itemFooter">
                            <Link to="/politica">Política de Privacidade</Link>
                        </li>
                    </ul>
                </div>

                {/* Contato */}
                <div className="footer-section">
                    <h4>ENTRE EM CONTATO</h4>
                    <p><a href="https://wa.me/5511962046061" target="_blank" rel="noopener noreferrer">+55 (11) 96204-6061</a></p>
                    <p>flashdent.12@gmail.com</p>
                </div>
            </div>

            {/* Rodapé final */}
            <div className="footer-bottom">
                <p>Flashdent©2024. Todos os Direitos Reservados.</p>
            </div>
        </footer>
    );
}

export default Footer;
