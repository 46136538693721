import React from "react";
import "../Details/termos.css";

function Termos() {
    return (
        <div className="termos">
            <h1>Termos de Uso</h1>
            <p>Última atualização: 13/11/2024</p>
            <p>Bem-vindo ao Flashdent! Ao acessar ou usar nossos serviços, você concorda com os seguintes Termos de Uso. Leia-os com atenção.</p>
            <br />
            
            <h2>1. Aceitação dos Termos</h2>
            <p>Ao acessar o site ou aplicativo Flashdent, você concorda em cumprir estes Termos de Uso e todas as leis e regulamentações aplicáveis. Se você não concorda com algum termo, não utilize nossos serviços.</p>
            <br />

            <h2>2. Uso dos Serviços</h2>
            <p>Você deve ser maior de 18 anos ou ter autorização de um responsável legal para utilizar nossos serviços. É proibido usar o Flashdent para atividades ilegais ou que violem direitos de terceiros.</p>
            <br />

            <h2>3. Direitos de Propriedade</h2>
            <p>Todo o conteúdo disponível no Flashdent, incluindo textos, imagens, logotipos e design, é protegido por leis de propriedade intelectual. É proibida a reprodução sem autorização prévia.</p>
            <br />
            
            <h2>4. Limitação de Responsabilidade</h2>
            <p>O Flashdent não se responsabiliza por danos diretos ou indiretos resultantes do uso de nossos serviços, incluindo indisponibilidade temporária do sistema.</p>
            <br />

            <h2>5. Alterações nos Termos</h2>
            <p>Reservamo-nos o direito de modificar os Termos de Uso a qualquer momento. Notificaremos os usuários sobre alterações significativas por meio de nosso site ou aplicativo.</p>
            <br />

            <h2>6. Contato</h2>
            <p>Se você tiver dúvidas sobre estes Termos de Uso, entre em contato conosco em <a href="mailto:flashdent.12@gmail.com">flashdent.12@gmail.com</a>.</p>
            <br />
        </div>
    );
}

export default Termos;
