import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Global/Header/header';
import Footer from './components/Global/Footer/footer';
import Termos from '../src/components/Pages/Details/termos';
import Politica from '../src/components/Pages/Details/politica';
import Contatos from '../src/components/Pages/Contatos/contatos';
import Home from './components/Pages/Home/home';
import Empresa from './components/Pages/Empresa/empresa';
import Carrossel from './components/Pages/Details/carrossel';
import Email from './components/Pages/Contatos/emailjs';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="carrossel" element={<Carrossel />} />
            <Route path="/empresa" element={<Empresa />} />
            <Route path="/contato" element={<Contatos />} />
            <Route path="/termos" element={<Termos />} />
            <Route path="/politica" element={<Politica />} />
            <Route path="/email" element={<Email />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
