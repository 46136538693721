import React from "react";
import "../Contatos/contatos.css";
import Email from "./emailjs";

function Contatos() {
    return (
        <div className="contatos">
            <h1>Contatos</h1>
            <p>Para solicitar nossos serviços, mande uma mensagem!</p>
            <br />
            <Email />
            <br />
            <p>Caso queira, entre em contato pelo nosso WhatsApp!</p>
            <p><strong>Telefone:</strong> <a href="https://wa.me/5511962046061" target="_blank" rel="noopener noreferrer">+55 (11) 96204-6061</a>.</p>
            <br />
        </div>
    );
}

export default Contatos;
