import React from "react";
import './home.css';
import '../Details/carrossel.css';
import Carrossel from "../Details/carrossel";
import Sulpack from '../../../assets/img/sulpack.png';
import Alt from '../../../assets/img/alt.png';

import Dentista from '../../../assets/img/dentistateste.png';

function Home() {
    return (
        <div className="content-home">
            <div className="carrossel-container">
                <Carrossel />
            </div>

            <div className="home">
                <h1>Bem-Vindo ao Flashdent!</h1>
                <p>
                    Oferecemos uma ampla gama de serviços especializados para dentistas e clínicas odontológicas, 
                    como conserto e manutenção de aparelhos e cadeiras de dentista, venda de equipamentos odontológicos 
                    de última geração, além de suporte técnico autorizado para atender todas as suas necessidades. 
                    Nossa equipe está sempre pronta para fornecer o melhor atendimento, com rapidez, eficiência e total confiança.
                </p>
                </div>
                
                
                <div className="servicos">
                    
                    <img src={Dentista} alt="Ilustração de um dentista" className="dentista-imagem" />
                    <br/>
                    <h2>Nossos Serviços:</h2>

                    <div className="servico">
                        <div className="servico-texto">
                            <p>
                                <strong>Manutenção e Conserto de Equipamentos Odontológicos:</strong> Realizamos reparos em cadeiras odontológicas, equipamentos de diagnóstico, compressores e outras ferramentas essenciais para o consultório. Nossos técnicos são treinados para assegurar que tudo funcione perfeitamente e dentro das normas de segurança.
                            </p>
                        </div>
                        
                    </div>

                    <div className="servico">
                        
                        <div className="servico-texto">
                            <p>
                                <strong>Venda de Equipamentos e Materiais Odontológicos:</strong> Trabalhamos com fornecedores renomados para oferecer aparelhos e materiais odontológicos de última geração. De equipamentos de imagem a instrumentais, nossa seleção é pensada para garantir eficiência e qualidade no atendimento ao paciente.
                            </p>
                        </div>
                    </div>

                    <div className="servico">
                        <div className="servico-texto">
                            <p>
                                <strong>Suporte Técnico Especializado:</strong> Nossa equipe está sempre disponível para resolver problemas técnicos, desde pequenas manutenções até o suporte para questões mais complexas. Oferecemos atendimento personalizado para garantir que as necessidades de cada clínica sejam atendidas com agilidade.
                            </p>
                        </div>
                       
                    </div>
                </div>
                
                <div className="posto">
                    <h2>Somos posto autorizado das empresas:</h2>
                    <a href="https://www.sulpack.com.br" target="_blank" rel="noopener noreferrer">
                        <img src={Sulpack} alt="Logo da Sulpack" className="sulpack-image" />
                    </a>
                    <a href="https://altequipamentos.com.br" target="_blank" rel="noopener noreferrer">
                        <img src={Alt} alt="Logo da Alt Equipamentos" className="alt-image" />
                    </a>
                    
                </div>
        </div>
    );
}

export default Home;
