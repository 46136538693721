import React from "react";
import "../Details/politica.css";

function Politica() {
  return (
    <div className="politica">
      <h1>Política de Privacidade</h1>
      <p><strong>Última atualização:</strong> 13/11/2024</p>
      
      <p>
        Sua privacidade é importante para nós. Esta Política de Privacidade explica como coletamos, usamos, armazenamos e protegemos suas informações.
      </p>
      <br />

      <h2>1. Informações que Coletamos</h2>
      <p>
        Podemos coletar as seguintes informações:
        <ul>
          <li>Dados pessoais, como nome, e-mail e telefone.</li>
        </ul>
      </p>
      <br />
      
      <h2>2. Uso das Informações</h2>
      <p>
        Utilizamos suas informações para:
        <ul>
          <li>Fornecer e melhorar nossos serviços.</li>
          <li>Entrar em contato com você em relação ao suporte.</li>
        </ul>
      </p>
      <br />

      <h2>3. Compartilhamento de Dados</h2>
      <p>
        Não compartilhamos suas informações pessoais com terceiros, exceto:
        <ul>
          <li>Quando exigido por lei.</li>
          <li>Com parceiros confiáveis para o funcionamento do serviço (como processadores de pagamento).</li>
        </ul>
      </p>
      <br />      

      <h2>4. Segurança</h2>
      <p>
        Adotamos medidas técnicas e organizacionais para proteger suas informações contra acesso não autorizado, perda ou destruição.
      </p>
      <br />

      <h2>5. Direitos do Usuário</h2>
      <p>
        Você tem o direito de acessar, corrigir ou excluir seus dados pessoais armazenados por nós. Entre em contato para exercer esses direitos.
      </p>
      <br />

      <h2>6. Alterações na Política</h2>
      <p>
        Reservamo-nos o direito de atualizar esta Política de Privacidade a qualquer momento. As alterações serão publicadas em nosso site ou aplicativo.
      </p>
      <br />

      <h2>7. Contato</h2>
      <p>
        Para dúvidas ou solicitações relacionadas à privacidade, envie um e-mail para <a href="mailto:flashdent.12@gmail.com">flashdent.12@gmail.com</a>.
      </p>
      <br />
    </div>
  );
}

export default Politica;
